@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/5f515bcadd82fbbd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/5f515bcadd82fbbd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/5f515bcadd82fbbd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/5f515bcadd82fbbd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/ef46db3751d8e999-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/ef46db3751d8e999-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/18f45dc7b59efb67-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/18f45dc7b59efb67-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/17f05e50789190f8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/17f05e50789190f8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/5f515bcadd82fbbd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/ef46db3751d8e999-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__spaceGrotesk_6e45d9';
src: url(/_next/static/media/ef46db3751d8e999-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}.__className_6e45d9 {font-family: '__spaceGrotesk_6e45d9'
}

@media (min-width: 640px) {
  @keyframes marquee {
    0% {
      left: 0;
    }

    100% {
      left: -340%;
    }
  }

  .animate {
    animation: marquee 20s linear infinite;
    transform: translate(0, -50%);
  }
}

@media (min-width: 768px) {
  @keyframes marquee {
    0% {
      left: 0;
    }

    100% {
      left: -355%;
    }
  }

  .animate {
    animation: marquee 20s linear infinite;
    transform: translate(0, -50%);
  }
}

@media (min-width: 1024px) {
  @keyframes marquee {
    0% {
      left: 0;
    }

    100% {
      left: -300%;
    }
  }

  .animate {
    animation: marquee 20s linear infinite;
    transform: translate(0, -50%);
  }
}

:root {
    --width: 100%;
    --height: 100%;
    --gradient: #000;
    --spotlight-color: rgb(255 255 255 / 40%);
    --accent-color: #fff;
    --accent-opacity: 0.15;
    --border-radius: 0;
    --top: 0;
    --left: 0;
    --is-hovered: 0;
    --cursor-x: 0;
    --cursor-y: 0;
    --angle: 0;
}

.container {
    width: var(--width);
    height: var(--height);
    background: var(--gradient);
    border-radius: var(--border-radius);
    position: absolute;
    top: var(--top);
    left: var(--left);
    transition: filter 0.3s ease-out, outline 0.3s ease-out;
    filter: brightness(100%);
    overflow: hidden;
    -webkit-mask: linear-gradient(rgb(0 0 0 / 100%), rgb(0 0 0 / 30%));
            mask: linear-gradient(rgb(0 0 0 / 100%), rgb(0 0 0 / 30%));
}

.grain {
    width: var(--width);
    height: var(--height);
    position: absolute;
    pointer-events: none;
    opacity: 0.13;
}

.border {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    background: linear-gradient(rgb(255 255 255 / 0%), rgb(255 255 255 / 0%)) padding-box,
    linear-gradient(var(--angle, 0deg), rgb(255 255 255 / 0%), rgb(255 255 255 / 20%)) border-box;
    -webkit-mask: linear-gradient(#fff, #fff) padding-box, linear-gradient(#fff, #fff);
            mask: linear-gradient(#fff, #fff) padding-box, linear-gradient(#fff, #fff);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    border: 2px solid transparent;
    pointer-events: none;
}

.border-highlight {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    background: radial-gradient(600px circle at var(--cursor-x, 0) var(--cursor-y, 0), rgb(255 255 255 / 70%) 0%, transparent 100%) padding-box,
    radial-gradient(600px circle at var(--cursor-x, 0) var(--cursor-y, 0), rgb(255 255 255 / 70%) 0%, transparent 100%) border-box;
    -webkit-mask: linear-gradient(#fff, #fff) padding-box, linear-gradient(#fff, #fff);
            mask: linear-gradient(#fff, #fff) padding-box, linear-gradient(#fff, #fff);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    border: 2px solid transparent;
    opacity: var(--is-hovered, 0);
    transition: opacity 0.3s ease-out;
    pointer-events: none;
}

.accent {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    background: radial-gradient(30% 60 circle at center -200px, var(--accent-color) 0%, transparent 100%);
    opacity: var(--accent-opacity);
    transition: opacity 0.3s ease-out;
    pointer-events: none;
}

.spotlight {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    background: radial-gradient(30% 60% at var(--cursor-x, 50%) var(--cursor-y, 50%), var(--spotlight-color) 0%, transparent 100%);
    opacity: var(--is-hovered, 0);
    transition: opacity 0.3s ease-out;
    pointer-events: none;
}

